@import "_generated-font-mixins.scss";
.sidebar {
  position: sticky;
  z-index: 1;
  top: 56px;

  float: right;

  width: 240px;

  background-color: #fff;
}

.aside {
  margin-bottom: 17px;
  font-size: 13px;
  line-height: 15px;
  color: #333;
}

.date,
.type,
.views {
  margin: 8px 0;
}

.price {
  margin-bottom: 10px;
  padding-top: 15px;
  letter-spacing: -1px;
  border-top: 1px solid #e6e6e6;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
  margin: 15px 0;
  padding: 15px 0;

  border-block: 1px solid #e6e6e6;
}

.address {
  height: auto;
  margin-bottom: 15px;
  padding-bottom: 15px;

  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  line-height: 22px;
  color: #ccc;

  border-bottom: 1px solid #e6e6e6;

  &__text {
    font-size: 15px;
    font-weight: 300;
    color: #000;
  }
}

.admixer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 240px;

  &__link {
    width: 100%;
    margin-top: 20px;
  }
}

.modal {
  width: 443px;
  padding: 20px;
}
